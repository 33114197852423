import { Component, OnInit } from '@angular/core';
import { OverflowControl } from "ngx-responsivemenu";
@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.css'],
  viewProviders: [OverflowControl]
})
export class MainmenuComponent implements OnInit {
  public items: string[] = [];
  customStickyLogic:boolean;
  tab : any = 'tab0';
  constructor() { }

  ngOnInit() {
    this.items = Array.from( Array.from({length: 10}), (v, index) => `Item #${index}`);
    
    this.seturl();
  }
seturl(){
  var geturl=window.location.href;
  if (geturl.includes('index'))
  {
    this.tab = 'tab0';
  }
  if (geturl.includes('about'))
  {
    this.tab = 'tab1';
  }
  if (geturl.includes('restaurant'))
  {
    this.tab = 'tab2';
  }
  if (geturl.includes('contact'))
  {
    this.tab = 'tab4';
  }
}
  FilterData(filterValue: string) {
    if(filterValue=='0'){
      this.tab = 'tab0';
    }
    else if(filterValue=='1'){
      this.tab = 'tab1';
    }
    else if(filterValue=='2'){
      this.tab = 'tab2';
    }
    else if(filterValue=='3'){
      this.tab = 'tab3';
    }
    else if(filterValue=='4'){
      this.tab = 'tab4';
    }
    else{
      this.tab = 'tab5';
    } 
  }

}
