import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css'],

})
export class MainContentComponent implements OnInit {
  constructor(private modalService: NgbModal) { }
 
  open(content) {
    this.modalService.open(content)
  }  


  isShow = true;
  isShowb = true;
 
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  toggleDisplayb() {
    this.isShowb = !this.isShowb;
  }
  ngOnInit() {
    window.scroll(0,0);
  }
  customOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  }
}
