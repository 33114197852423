import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainContentComponent } from './main-content/main-content.component';
import { RestaurantComponent} from './restaurant/restaurant.component';
import { RetailComponent} from './retail/retail.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';

const routes: Routes = [
  {
    path:'',
    component: MainContentComponent,
    children:[
      { path:'', component: MainContentComponent, pathMatch: 'full'},
      {
        path:'index',component:MainContentComponent,pathMatch: 'full'
      },
    ],
  },
  {
    path:'',
    children:[
      {
       path:'restaurant',component:RestaurantComponent
     },
     {
      path:'retail',component:RetailComponent
    },
     {
       path:'about',component:AboutusComponent
     },
     {
       path:'contact',component:ContactusComponent
     },
    ]},
  
    { path: '**', redirectTo: 'main' },
    { path: '', redirectTo: 'main', pathMatch: 'full' },    
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
