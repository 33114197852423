import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import{environment} from '../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class MailserviceService {

  constructor(private http: HttpClient) { }

  SendMail(data:any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    
    return this.http.post<any>(environment.mailurl,data);
 
    //return this.http.post<any>(environment.mailurl+'?name='+name+'&email='+email+'&company='+company+'&enquery='+enquery,
    // return this.http.post<any>(environment.mailurl,JSON.stringify(config),
    //  httpOptions);
    // return this.http.post<any>(environment.mailurl+'?name='+nameobj+'&email='+emailobj+'&company='+companyobj+'&enquery='+enqueryobj,httpOptions
    //  );
  }


}