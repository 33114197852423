import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-retail',
  templateUrl: './retail.component.html',
  styleUrls: ['./retail.component.css']
})
export class RetailComponent implements OnInit {

  constructor(private modalService: NgbModal) { }
  
  open(content) {
    this.modalService.open(content)
  } 
isShow = true;
isShowb = true;

toggleDisplay() {
  this.isShow = !this.isShow;
}
toggleDisplayb() {
  this.isShowb = !this.isShowb;
}
  ngOnInit() {
    window.scroll(0,0);
  }

}
