import { Component, OnInit } from '@angular/core';
import { MailserviceService } from '../mailservice.service';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {constructor(private mailservice:MailserviceService) { }
isShow = true;
isShowb = true;

toggleDisplay() {
  this.isShow = !this.isShow;
}
toggleDisplayb() {
  this.isShowb = !this.isShowb;
}

  ngOnInit() {
    window.scroll(0,0);
  }
  onFormSubmit() {
    debugger;

    var name=(<HTMLInputElement>document.getElementById("name")).value;
    var phone=(<HTMLInputElement>document.getElementById("phone")).value;
    var email=(<HTMLInputElement>document.getElementById("email")).value;
    var company=(<HTMLInputElement>document.getElementById("company")).value;
    var enquery=(<HTMLInputElement>document.getElementById("enquery")).value;
    
    var letters = /^[A-Za-z\s-, ]+$/;
    if(!name.match(letters))
    {
      alert('Please input alphabet characters only in Name field');
      return false;
    }
    var phoneno = /^([0-9])/;;
    if (!phone.match(phoneno)) {
      alert('Please provide a valid Phone Number');
      return false;
    }
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!email.match(filter)) {
      alert('Please provide a valid email address');
      return false;
    }
        
    let formdata: FormData = new FormData();

    formdata.append("name",name);
    formdata.append("email",email);
    formdata.append("company",company);
    formdata.append("enquery",enquery);
  

    this.mailservice.SendMail(formdata).subscribe(data=>{
if(data==1)
{
  alert("Thank you we will contact you shortly");
  (<HTMLInputElement>document.getElementById("name")).value = '';
  (<HTMLInputElement>document.getElementById("phone")).value = '';
  (<HTMLInputElement>document.getElementById("email")).value = '';
  (<HTMLInputElement>document.getElementById("company")).value = '';
  (<HTMLInputElement>document.getElementById("enquery")).value = '';
  this.isShowb = !this.isShowb;
}
    })
  }
}
