import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
  
})
export class ContactusComponent implements OnInit {constructor() { }
isShow = true;
isShowb = true;

toggleDisplay() {
  this.isShow = !this.isShow;
}
toggleDisplayb() {
  this.isShowb = !this.isShowb;
}
  ngOnInit() {
    window.scroll(0,0);
  }

}
