import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainmenuComponent } from './mainmenu/mainmenu.component';
import { ResizableModule } from 'angular-resizable-element';
import { ResponsiveMenuModule } from "ngx-responsivemenu";
import { StickyNavModule } from 'ng2-sticky-nav';
import { MainContentComponent } from './main-content/main-content.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { MatFormFieldModule,MatInputModule,MatRippleModule} from '@angular/material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactusComponent } from './contactus/contactus.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RetailComponent } from './retail/retail.component';


@NgModule({
  declarations: [
    AppComponent,
    MainmenuComponent,
    MainContentComponent,
    FooterComponent,
    RestaurantComponent,
    AboutusComponent,
    ContactusComponent,
    RetailComponent,
    
  ],
  imports: [
    
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    BrowserModule,
    AppRoutingModule,
    ResponsiveMenuModule,ResizableModule,StickyNavModule,CarouselModule,BrowserAnimationsModule,
    FormsModule,ReactiveFormsModule, NgbModule
    // MDCFormField
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
